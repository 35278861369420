import React, { Component } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// TO USE: takes in two props: 1) buttonPage - page name to link to and 2) buttonText - text inside button
export class ScrollButton extends Component {
    render() {
        return (
            <div>
                <AnchorLink to={this.props.buttonPage} title={this.props.title} alt={this.props.alt} stripHash>
                    <button className="m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out">
                        <span>{this.props.buttonText}</span>
                    </button>
                </AnchorLink>
            </div >
        )
    }
}